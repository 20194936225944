<div class="wrapper">
  <header></header>
  <footer>
    <a cf-link routerLink="/rules/privacy-policy" target="_blank">
      {{ 'auth.privacy_security_policy' | translate }}
    </a>

    <a
      cf-link
      class="ms-3"
      routerLink="/rules/terms-of-service"
      target="_blank"
    >
      {{ 'auth.terms_of_service' | translate }}
    </a>
  </footer>
</div>
